import { memo, useState } from "react";
import { KeyedMutator } from "swr";
import { aggregate, formatNumber } from "@/lib/util";
import Loader from "@/components/ui/Loader";
import { Badge, Flex, Heading, Tabs, Text } from "@radix-ui/themes";
import NewMultiSelect from "../../../CustomComponents/NewMultiSelect";
import MassPostImport from "../../../Campaign/modals/NewMassImport";
import NewCreateReport from "../../../Campaign/modals/NewCreateReport";
import NewShareLink from "../../../Campaign/modals/NewShareLink";
import Playlists from "../../Playlists";
import NewMassImport from "../../../Campaign/modals/NewMassImport";
import NewAddDraft from "@/components/Campaign/modals/NewAddDraft";
import NewAddBudget from "@/components/Campaign/modals/NewAddBudget";
import PostsTab from "../../Advanced/PostsTab";
import SoundsTab from "../../Advanced/SoundsTab";
import DraftsTab from "../../Advanced/DraftsTab";
import CreatorsTab from "../../Advanced/CreatorsTab";
import SettingsTab from "../../Advanced/SettingsTab";
import { MobileTotals } from "../MobileDashboardView";
import MobilePostsListView from "../MobilePostsListView";
  
  interface Props {
  editable: boolean;
  shareable: boolean;
  reportable: boolean;
  transferable: boolean;
  data: Campaign | undefined;
  mutate: KeyedMutator<Campaign>;
}

const MobileAdvancedView = ({
  data,
  editable,
  mutate,
  shareable,
  transferable,
  reportable,
}: Props) => {
  const ALL_TYPES = [
    "Tiktok",
    "Instagram",
    "Twitter",
    "Youtube",
  ]
  const [types, setTypes] = useState<string[]>(ALL_TYPES);
  const [sortKey, setSortKey] = useState("views");
  const [sortDir, setSortDir] = useState("desc");
  const [tags, setTags] = useState([] as string[]);
  const [selectedTags, setSelectedTags] = useState([] as string[]);

  const playlists = data?.post?.filter(
    ({ type }) => type === "spotify-playlist",
  );

  const currentTags = Array.from(new Set(data?.tags)).sort();
  if (JSON.stringify(tags) != JSON.stringify(currentTags)) {
    setTags(currentTags);
  }

  let posts = data?.post
    ?.filter(({ type }) =>
      ["instagram", "tiktok", "twitter", "youtube"].includes(type),
    )
    .filter(
      (post) =>
        !selectedTags.length ||
        post.tags.some((tag) => selectedTags.includes(tag)),
    )
    .filter((post) => types.map((i) => i.toLowerCase()).includes(post.type))
    .sort(
      (a: any, b: any) =>
        (sortKey == "postedAt"
          ? (new Date(a[sortKey]) as any) - (new Date(b[sortKey]) as any)
          : sortKey == "username"
            ? a[sortKey]?.localeCompare(b[sortKey])
            : a[sortKey] - b[sortKey]) * (sortDir === "asc" ? 1 : -1),
    );
  const audio = data?.post.filter(({ type }) =>
    ["instagram-audio", "tiktok-audio"].includes(type),
  );

  if (!data) {
    return <Loader full={false} />;
  }

  return (
    <>
    <Flex id="campaign-header" direction="row" justify="between" gap="4" pb="4" p="5">
      <Heading size="4">Campaign Report</Heading>
      <Flex gap="5">
        {reportable &&
          <NewCreateReport
          campaign={data}
          tags={selectedTags}
          sortDir={sortDir}
          sortKey={sortKey}
          types={types}/>}
        {shareable && 
        <NewShareLink
          id={`${window.location.protocol}//${window.location.hostname}/share/${data.shareCode}`}
            />}
      </Flex>
    </Flex>
    <Tabs.Root defaultValue="posts">
        <Tabs.List size="2">
          <Tabs.Trigger value="posts">
            <Flex direction="row" gap="1">
            Posts
            <Badge>{posts && posts.length}</Badge>
            </Flex>
          </Tabs.Trigger>
          <Tabs.Trigger value="tracking">
            <Flex direction="row" gap="1">
              Tracking
              <Badge>{audio && audio.length}</Badge>
            </Flex>
          </Tabs.Trigger>
          {/* <Tabs.Trigger value="postdrafts">
            <Flex direction="row" gap="1">
              Post Drafts
              <Badge>{data.postDrafts && data.postDrafts.length}</Badge>
            </Flex>
          </Tabs.Trigger> */}
          {editable && <Tabs.Trigger value="creators">
          <Flex direction="row" gap="1">
              Creators
              <Badge>{data.budgets && data.budgets.length}</Badge>
            </Flex>
          </Tabs.Trigger>}
          <Tabs.Trigger value="playlists">
            <Flex direction="row" gap="1">
              Playlists
              <Badge>{playlists && playlists.length}</Badge>
            </Flex>
          </Tabs.Trigger>
          <Tabs.Trigger value="settings">Settings</Tabs.Trigger>
        </Tabs.List>

    <Tabs.Content value="posts">
      <Flex direction="column" gap="5" pt="4">
      <Flex className="box-mobile" direction="column" p="5" gap="3">
        <Heading size="4">Post Analytics</Heading>
        <Flex direction="row" gap="3" pb="2">
          <NewMultiSelect transparent style={{width: 130, marginRight: 10}} setSelected={setSelectedTags} title="Tags" selected={selectedTags} options={Array.from(new Set(data?.tags)).toSorted() as any}/>
          <NewMultiSelect transparent style={{width: 140, marginRight: 10}} setSelected={setTypes} title="Platform" selected={types} options={ALL_TYPES}/>
        </Flex>
        <Flex direction="row" gap="2" justify="between">
            <MobileTotals
                title="Views"
                value={formatNumber(aggregate(posts, "views"))}
                />
            <MobileTotals
                title="Likes"
                value={formatNumber(aggregate(posts, "likes"))}
                />
          </Flex>
          <Flex direction="row" gap="2" justify="between">
              <MobileTotals
                  title="Shares"
                  value={formatNumber(aggregate(posts, "shares"))}
                  />
              <MobileTotals
                  title="Comments"
                  value={formatNumber(aggregate(posts, "comments"))}
                  />
          </Flex>
      </Flex>
        <Flex className="box" direction="column" gap="5" p="5">
          <Flex direction="row" justify="between">
            <Heading size="4">Posts</Heading>
            {editable && <NewMassImport type="post" mutate={mutate} campaignId={data.id}/>}
          </Flex>
          <MobilePostsListView fullPosts={posts || []} mutate={mutate} editable={editable}/>
        </Flex>
      </Flex>
    </Tabs.Content>

    <Tabs.Content value="tracking">
    <Flex direction="column" pt="4" gap="3">
      <Flex direction="row" justify="between" pl="1">
          <Heading size="4">Tracking - Sounds & Hashtags</Heading>
          {editable && <MassPostImport type="sounds" mutate={mutate} campaignId={data.id}/>}
        </Flex>
      <Flex className="box" direction="column" p="5">
        <SoundsTab audio={audio || []} mutate={mutate} editable={editable}/>
      </Flex>
    </Flex>
    </Tabs.Content>

    <Tabs.Content value="postdrafts">
    <Flex direction="column" pt="4" gap="3">
      <Flex direction="row" justify="between" pl="1">
          <Heading size="4">Post Drafts</Heading>
          {editable && <NewAddDraft mutate={mutate} campaignId={data.id}/>}
      </Flex>
      <Flex className="box" direction="column" p="5">
        <DraftsTab
        drafts={data.postDrafts}
        mutate={mutate}
        editable={editable}
        id={data.id}/>
      </Flex>
    </Flex>
    </Tabs.Content>

    {editable && <Tabs.Content value="creators">
    <Flex direction="column" pt="4" gap="2">
      <Flex direction="row" justify="between" px="5">
          <Heading size="4">Creators</Heading>
          <NewAddBudget mutate={mutate} campaignId={data.id}/>
      </Flex>
      <CreatorsTab campaign={data} mutate={mutate} style="mobile"/>
    </Flex>
    </Tabs.Content>}

    <Tabs.Content value="playlists">
      <Flex direction="column" pt="4">
        <Flex className="box" p="5">
          <Playlists
            fullPlaylists={playlists}
            spotifyPlaylists={JSON.parse(data?.song?.playlists || "{}")}
            style="mobile"/>
        </Flex>
      </Flex>
    </Tabs.Content>

    <Tabs.Content value="linkpages">
    </Tabs.Content>

    <Tabs.Content value="settings">
      <SettingsTab 
        transferable={transferable}
        campaign={data}
        mutate={mutate}
        style="mobile"/>
    </Tabs.Content>
</Tabs.Root>
</>
          
  );
};
export default memo(MobileAdvancedView);
