import { formatNumber } from "@/lib/util";
import { Flex, Heading, Select, Table, Text } from "@radix-ui/themes";
import { IconBrandSpotify } from "@tabler/icons-react";
import Link from "next/link";
import { memo, useState } from "react";

interface Props {
  fullPlaylists: any;
  spotifyPlaylists: any;
  style?: string;
}

export const findSpotifyInfo = (
  spotifyPlaylists: any,
  url: string,
  filter: string,
) => {
  const id = url.split("/").pop();
  if (!spotifyPlaylists) return {};
  console.log("findSpotifyInfo", filter, url ,spotifyPlaylists)
  const item = spotifyPlaylists[filter]?.find(
    (item: any) => item.uri === `spotify:playlist:${id}`,
  );

  const any: any = Object.values<any>(spotifyPlaylists)
    .find((i: any) =>
      i.find((item: any) => item.uri === `spotify:playlist:${id}`),
    )
    ?.find((item: any) => item.uri === `spotify:playlist:${id}`);
  let res: any = {};
  if (item) {
    res = { ...item };
    res.streams = parseInt(item.streams);
  }
  res.title = any?.title;
  return res;
};

const Playlists = ({
  fullPlaylists,
  spotifyPlaylists,
  style,
}: Props) => {
  const playlists = fullPlaylists?.sort((a: any, b: any) => b.views - a.views);
  const [shown, setShown] = useState("1day");
  const headers = ["Title", "Streams", "Link"]
console.log("playlists", playlists)
  return (
    <Flex direction="column" width="100%" gap="3">
      <Flex direction="row" justify="between">
        <Heading size="4">Spotify Playlists</Heading>
        <Select.Root defaultValue="1day" onValueChange={(value)=>(setShown(value))}>
          <Select.Trigger />
          <Select.Content>
            <Select.Item value="1day">24 Hours</Select.Item>
            <Select.Item value="7day">7 Days</Select.Item>
            <Select.Item value="28day">28 Days</Select.Item>
            <Select.Item value="1year">1 Year</Select.Item>
          </Select.Content>
        </Select.Root>
      </Flex>
      {playlists?.length ? <Table.Root size="1">
            <Table.Header>
                <Table.Row>
                    {headers.map((item: any) => (
                        <Table.ColumnHeaderCell key={item}>{item}</Table.ColumnHeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {playlists && playlists.map((item) => (
                    <Table.Row align="center" key={item.url}>
                        <Table.Cell>
                          <Flex py="2">
                          {findSpotifyInfo(spotifyPlaylists, item.url, shown)?.title}
                          </Flex>
                        </Table.Cell>
                        <Table.Cell>
                          {formatNumber(findSpotifyInfo(spotifyPlaylists, item.url, shown)?.streams)}
                        </Table.Cell>
                        <Table.Cell>
                        {style === "mobile" ?
                          <Link href={item.url} className="underline" target="_blank">
                            <IconBrandSpotify className="icon-accent"/>
                          </Link>:
                          <Link href={item.url} className="underline" target="_blank">
                            View On Spotify
                          </Link>}
                        </Table.Cell>
                    </Table.Row>
                ))} 
            </Table.Body>
        </Table.Root>:
        <Text>
        No playlists yet.
      </Text>}
    </Flex>
  );
};

export default memo(Playlists);
