import { Table } from "@radix-ui/themes";
import MobilePostListRow from "./MobilePostsListRow";
import { KeyedMutator } from "swr";

interface PostsListViewProps {
    fullPosts: Post[];
    mutate?: KeyedMutator<Campaign>;
    editable: boolean;
}
const MobilePostsListView = ({ fullPosts, mutate, editable }: PostsListViewProps) => {
    const posts = fullPosts.sort((a: any, b: any) => b.views - a.views);
    return (
        <Table.Root size="1">
            <Table.Body>
                {posts.map((post) => (
                    <Table.Row align="center" key={post.id}>
                        <Table.Cell>
                            <MobilePostListRow mutate={mutate || null} post={post} editable={editable}/>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
            </Table.Root>
    )
}

export default MobilePostsListView