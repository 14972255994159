"use client";
import fetchJson from "@/lib/fetchJson";
import { useRef, useState } from "react";
import useSWR from "swr";
import Select from "@/components/ui/Select";
import { capitalize } from "@/lib/util";
import { Button, Flex, Text, TextField } from "@radix-ui/themes";
import { IconPlus, IconUpload } from "@tabler/icons-react";
import * as Dialog from "@/components/CustomComponents/Dialog"

export default function NewAddDraft({ mutate, campaignId }: any) {
  const { data } = useSWR<Creator[]>("/creator", fetchJson);
  const [selectedCreator, setSelectedCreator] = useState(null as any);
  const { data: creator } = useSWR<any>(`/creator/${selectedCreator?.value}`);
  const [price, setPrice] = useState(null as any);

  const ref = useRef<any>(null);
  const handleChange = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setVideo(file);
      setPreview(file.name);
    }
    console.log(e.target.files[0]);
  };

  const submit = async (close: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("video", video);
    formData.append("campaign", campaignId);
    formData.append("creator", price.value);
    const data = await fetch(`/backend/postDraft`, {
      method: "POST",
      body: formData,
    });
    setLoading(false);
    mutate();
    close();
  };

  const [campaign, setCampaign] = useState<any>(null);
  const [video, setVideo] = useState<any>(null);
  const [preview, setPreview] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Button>
          <IconPlus className="icon-accent"/>
          Add Draft
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
      <Dialog.Content style={{maxWidth: "450px"}}>
        <Dialog.Title>Add Post Draft</Dialog.Title>

        <Flex direction="column" gap="3">
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
          Creator
          </Text>
          {/* <SearchableSelect
                selected={selectedCreator}
                options={(data || []).map((i) => ({ value: i.id, text: i.name }))}
                onSelect={setSelectedCreator}
              /> */}
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
          Platform
          </Text>
          <Select
                title=""
                selected={price}
                width="w-64"
                options={(creator?.creator?.prices || []).map((i: any) => ({
                  value: i.id,
                  text: `@${i.username} (${capitalize(i.platform)})`,
                }))}
                setSelected={setPrice}
              />
        </label>
        <label>
          <Text as="div" size="2" mb="1" weight="bold">
          Content
          </Text>
          <input type="file" hidden ref={ref} onChange={handleChange} />
          <Button
            variant="outline"
            onClick={() => ref.current?.click()}
            className="w-72"
          >
            <IconUpload className="icon-accent" width="18px"/>
            Select file
          </Button>
          {preview && <div className="mt-3 text-sm">{preview}</div>}
        </label>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button 
            onClick={() => submit(close)}
            loading={loading}
            >
              Save
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
