import NewEditSound from "@/components/Campaign/modals/NewEditSound";
import { formatNumber, shortenLink } from "@/lib/util"
import { deletePost } from "@/services/post";
import { Button, Flex, IconButton, Link, Table, Text } from "@radix-ui/themes"
import { IconTrash } from "@tabler/icons-react";
import { KeyedMutator } from "swr";

interface SoundsTabProps {
    audio: Post[]
    mutate: KeyedMutator<Campaign>; 
    editable: boolean
}
const SoundsTab = ({audio, mutate, editable}: SoundsTabProps) => {
    const soundHeaders = [
        "Title",
        "Artist",
        "URL",
        "Tags",
        "Posts",
      ]
    const removePost = async (id: number) => {
        if (confirm("Are you sure you want to delete this post?")) {
          await deletePost(id);
          mutate();
        }
      };

    return (
        <>
{audio?.length ? (
    <Table.Root className="w-full text-sm text-left text-main-text">
      <Table.Header className="text-xs uppercase">
        <Table.Row>
      {soundHeaders.map((item: any) => (
          <Table.ColumnHeaderCell key={item}>{item}</Table.ColumnHeaderCell>
      ))}
      {editable && <Table.ColumnHeaderCell key={"Actions"}>{"Actions"}</Table.ColumnHeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {audio?.map((sound) => (
          <Table.Row key={sound.id}>
            <Table.Cell>{sound.title && sound.title}</Table.Cell>
            <Table.Cell>{sound.fullName && sound.fullName}</Table.Cell>
            <Table.Cell>
                {sound.url && <Link href={sound.url} target="_blank">
                  {shortenLink(sound.url).length > 40
                    ? shortenLink(sound.url).substring(0, 40) +
                    "..."
                    : shortenLink(sound.url)}
                </Link>}
            </Table.Cell>
            <Table.Cell>{sound.tags && sound.tags.join(", ")}</Table.Cell>
            <Table.Cell>
              {sound.posts && formatNumber(sound.posts)}
            </Table.Cell>
            {editable && (
              <Table.Cell>
                <Flex direction="row" align="center" gap="2">
                  <Link href={`/post/${sound.id}`}>
                      <Button variant="outline">History</Button>
                  </Link>
                  { sound.tags && <NewEditSound mutate={mutate} sound={sound} />}
                  <IconButton variant="outline" onClick={() => removePost(sound.id)}>
                      <IconTrash className="icon-accent" />
                  </IconButton>
              </Flex>
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  ) : (
    <Text>
      No sounds yet.
    </Text>
  )}
  </>
)
}

export default SoundsTab