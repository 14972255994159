import { Badge, Button, Flex, Heading, IconButton, Table, Text, TextField } from "@radix-ui/themes";
import Link from "next/link";
import { IconChevronLeft, IconChevronRight, IconSearch, IconTrash } from "@tabler/icons-react";
import { KeyedMutator } from "swr";
import { deletePost } from "@/services/post";
import NewEditPost from "../../Campaign/modals/NewEditPost";
import Image from "next/image";
import { memo, useEffect, useState } from "react";
import NewMassImport from "@/components/Campaign/modals/NewMassImport";

interface PostsTabProps {
    fullPosts: Post[];
    mutate: KeyedMutator<Campaign>;
    id: any;
    editable: boolean;
}

const PostsTab = ({ fullPosts, mutate, editable, id}: PostsTabProps) => {
    const [page, setPage] = useState(0)
    const PAGE_SIZE = 10
    const headers = ["Post", "Tags", "Author", "Post Date", "Likes", "Shares", "Views", "Comments"]
    const posts = fullPosts.sort((a: any, b: any) => b.views - a.views);
    const removePost = async (id: number) => {
        if (confirm("Are you sure you want to delete this post?")) {
          await deletePost(id);
          mutate();
        }
      };
    const [search, setSearch] = useState("");
    const searched = posts?.filter((i: any) =>
    i.username?.toLowerCase().includes(search.toLowerCase()),
    );

    useEffect(() => {
        setPage(0);
      }, [search]);
    
    return (
        <Flex className="box" direction="column" gap="5" p="5">
             <TextField.Root
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search creators..."
              radius="medium"
              variant="soft"
                >
                <TextField.Slot pr="4">
                  <IconSearch className="icon-dark" width="20px"/>
                </TextField.Slot>
      </TextField.Root>
          <Flex direction="row" justify="between">
            <Heading size="4">Posts</Heading>
            {editable && <NewMassImport type="post" mutate={mutate} campaignId={id}/>}
          </Flex>
        {posts?.length ? <Table.Root size="1">
            <Table.Header>
                <Table.Row>
                    {headers.map((item: any) => (
                        <Table.ColumnHeaderCell key={item}>{item}</Table.ColumnHeaderCell>
                    ))}
                    {editable && <Table.ColumnHeaderCell key={"Actions"}>{"Actions"}</Table.ColumnHeaderCell>}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {searched && searched.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((post) => (
                    <Table.Row align="center" key={post.id}>
                        <Table.Cell>
                        <Link href={post.url} target="_blank">
                            <div className="small-thumbnail" style={{position: "relative"}}>
                            <Image
                            className="small-thumbnail"
                            fill
                            alt=""
                            src={`https://wvemedia.com/image-upload/${post.thumbnailSmall}`}
                            />
                            </div>
                        </Link>
                        </Table.Cell>
                        <Table.Cell>{post.tags && post.tags.map((tag: string) => ( 
                            <Badge key={tag} >{tag}</Badge>
                        ))}</Table.Cell>
                        <Table.Cell>{post.username && <Text>@{post.username}</Text>}</Table.Cell>
                        <Table.Cell>{post.postedAt && new Date(post.postedAt).toLocaleDateString()}</Table.Cell>
                        <Table.Cell>{(post.shares && post.views && !post.likes )? 'Hidden' : post.likes ? post.likes.toLocaleString() : ''}</Table.Cell>
                        <Table.Cell>{post.shares && post.shares.toLocaleString()}</Table.Cell>
                        <Table.Cell>{post.views && post.views.toLocaleString()}</Table.Cell>
                        <Table.Cell>{post.comments && post.comments.toLocaleString()}</Table.Cell>
                        {editable && <Table.Cell> 
                            <Flex direction="row" align="center" gap="2">
                                <Link href={`/post/${post.id}`}>
                                    <Button variant="outline">History</Button>
                                </Link>
                                { post.tags && <NewEditPost mutate={mutate} post={post} />}
                                <IconButton variant="outline" onClick={() => removePost(post.id)}>
                                    <IconTrash className="icon-accent" />
                                </IconButton>
                            </Flex>
                        </Table.Cell>}
                    </Table.Row>
                ))}
            </Table.Body>
            </Table.Root> : 
            <Text>
                No posts yet.
            </Text>}
            <Flex direction="row" gap="3" justify="center">
                <Button variant="ghost" disabled={page === 0} onClick={() => setPage((prev: any) => prev - 1) }><IconChevronLeft className="icon-accent"/>Previous</Button>
                <Button variant="ghost" disabled={page >= (searched?.length / PAGE_SIZE - 1)} onClick={() => setPage((prev: any) => prev + 1) }>Next<IconChevronRight  className="icon-accent"/></Button>
            </Flex>
            </Flex>
    )
}

export default memo(PostsTab);