"use client";
import { findPrice, formatNumber } from "@/lib/util";
import { Flex, Heading, Progress, Text } from "@radix-ui/themes";
import { findSpotifyInfo } from "./Playlists";
import { memo } from "react";

const NewCampaignProgress = ({
  campaign,
  spotifyPlaylists,
}: {
  campaign: Campaign;
  spotifyPlaylists: any;
}) => {
  const totalSpent = campaign?.post
    .map((p) => findPrice(campaign, p))
    .filter((i) => i)
    .map((i) => i?.price || 0)
    .reduce((a, b) => a + b, 0);
  const budgetPercent = Math.round((totalSpent / (campaign.instagramBudget + campaign.tiktokBudget)) * 100) || 0
  const totalRealizedPosts = campaign.post.filter((i) => ["tiktok", "instagram", "twitter"].includes(i.type)).length
  const totalBookedPosts = 
    campaign.budgets.map((item: any) => item.budget).reduce((a: number, b: number) => a + b, 0)
  const postsPercent = Math.round((totalRealizedPosts / totalBookedPosts) * 100) || 0
  const playlistStreams = campaign.id === 228 ? 93728 : campaign.id === 399 ? 65801
      : campaign.post.filter((i) => i.type == "spotify-playlist")
          .map((i) => findSpotifyInfo(spotifyPlaylists, i.url, "1year")?.streams)
          .filter((i) => i)
          .reduce((a, b) => a + b, 0)
  const playlistsPercent = Math.round((playlistStreams / campaign.streamTarget) * 100) || 0
  const daysPassed = Math.max(0, Math.ceil((new Date().getTime() - new Date((campaign as any).createdAt).getTime()) / 8.64e7))

  return (
    <Flex direction="column" gap="6" p="5" width="100%">
      <Heading size="4">Campaign Progress</Heading>
      <Flex direction="column" gap="2">
        <Text size="2">Budget Spent so Far</Text>
        <Heading size="8">{budgetPercent}%</Heading>
        <Progress size="3" radius="medium" value={budgetPercent <= 100 ? budgetPercent : 100}/>
      </Flex>
      <Flex direction="column" gap="2">
        <Text size="2">Paid Posts Posted</Text>
        <Heading size="8">{totalRealizedPosts}</Heading>
        <Flex direction="row" justify="between">
          <Text size="2">Of {totalBookedPosts}</Text>
          <Text size="2">{postsPercent}%</Text>
        </Flex>
      </Flex>
      {campaign?.streamTarget !== 0 ? 
      <Flex direction="column" gap="2">
        <Text size="2">Playlist Streams</Text>
        <Heading size="8">{formatNumber(playlistStreams)}</Heading>
        <Flex direction="row" justify="between">
          <Text size="2">Of {formatNumber(campaign.streamTarget)}</Text>
          <Text size="2">{playlistsPercent}%</Text>
        </Flex>
      </Flex> : 
        <Flex direction="column" gap="2">
          <Text size="2">Daily Streams</Text>
          <Heading size="8">{formatNumber(campaign.dailyStreams * daysPassed)}</Heading>
      </Flex>}
    </Flex>
  );
};

export default memo(NewCampaignProgress);
