import { format, subDays } from "date-fns";
import { memo } from "react";
import dynamic from 'next/dynamic'

interface ActivityProps {
    spotify: any;
    ugc: any[];
    artist: any;
    streams: any;
    loading: boolean;
    longPlaylists: any;
    shown: string[];
}

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

const ApexActivityGraph = ({
    loading,
    spotify,
    ugc,
    artist,
    streams, //ugc streams?
    longPlaylists,
    shown,
}: ActivityProps) => {
    const labels = Array.from({ length: 28 }, (_, i) =>
        format(subDays(new Date(), i), "yyyy-MM-dd"),
    ).reverse();

    const artistSpotify = JSON.parse(artist?.followers || "{}");

    const followers = artistSpotify?.followers?.current_period_timeseries
        ?.filter((item: any) => labels.includes(format(new Date(item.x), "yyyy-MM-dd")))
        ?.map((item: any) => ({ x: item.x.replace(/\//g, "-"), y: parseInt(item.y) }))

    const adds = spotify?.playlist_adds?.current_period_timeseries
        ?.filter((item: any) => labels.includes(format(new Date(item.x), "yyyy-MM-dd")))
        ?.map((item: any) => ({ x: item.x.replace(/\//g, "-"), y: parseInt(item.y) }))

    const streaming = spotify?.streams?.current_period_timeseries
        ?.filter((item: any) => labels.includes(format(new Date(item.x), "yyyy-MM-dd")))
        ?.map((item: any) => ({ x: item.x.replace(/\//g, "-"), y: parseInt(item.y) }))

    const playlistStreams = longPlaylists?.filter((item: any) => labels.includes(format(new Date(item[0]), "yyyy-MM-dd")))
        ?.map((item: any) => ({ x: item[0].replace(/\//g, "-"), y: parseInt(item[1]) })) || []

    const finalUgc = ugc?.filter((item: any) => labels.includes(item.x))
    const theme = localStorage.getItem("theme") || "dark"

    const THEME_MODE = {
        "blue": "light",
        "dark": "dark",
        "light": "light",
        "research": "dark",
        "purple": "dark",
        "grey": "dark",
    }



    const currentDate = new Date();

    // Create a copy of the current date to avoid modifying the original date
    const twoMonthsAgo = new Date(currentDate);

    // Subtract 2 months from the current date
    twoMonthsAgo.setMonth(currentDate.getMonth() - 2);

    let series = [{
        name: 'Followers',
        data: followers
    },
    {
        name: 'Playlist Adds',
        data: adds
    }, {
        name: 'Streams',
        data: streaming
    }, {
        name: 'UGC Videos',
        data: finalUgc
    },
    {
        name: 'Playlist Streams',
        data: playlistStreams
    }
    ]

    series = series.filter(series => shown?.includes(series?.name))
    const hasData = series?.length > 0 && series?.some(item => item?.data?.length > 0)

    const state = {

        series: series,
        options: {
            noData: {
                text: "No data available",
                align: 'center' as any,
                verticalAlign: 'middle' as any,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '14px',
                }
            },
            stroke: {
                width: 2
            },
            grid: {
                show: false
            },
            theme: {
                mode: THEME_MODE[theme] as any
            },
            chart: {
                type: "area" as any,
                stacked: false,
                height: 350,
                background: '0',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout' as any,
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }

                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
                hover: {
                    size: 0
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.45,
                    opacityTo: 0.05,
                    stops: [20, 100, 100, 100]
                },
            },
            yaxis: {
                lines: {
                    show: false
                },
                labels: {
                    show: hasData,
                    style: {
                        colors: '#8e8da4',
                    },
                    offsetX: 0,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false
                }
            },
            xaxis: {
                show: series.length > 0,
                lines: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                tickAmount: 4,
                type: 'datetime' as any,
                tooltip: {
                    enabled: false
                },
                labels: {
                    formatter: function (val, timestamp) {
                        return format(new Date(val), "yyyy-MM-dd")
                    },
                    show: hasData
                }
            },
            tooltip: {
                shared: false,
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                marker: {
                    show: false
                }
            },

            legend: {
                show: false
            }
        },
    };

    return (
        <div id="chart">
            <Chart options={state.options} series={state.series} type="area" height={350} />
        </div>
    )


}

export default memo(ApexActivityGraph);

