import { updateCampaign, updateTracking } from "@/services/campaign";
import { Badge, Box, Button, Flex, Heading, IconButton, Separator, Switch, Text, TextField } from "@radix-ui/themes";
import MergeCampaign from "@/components/Campaign/modals/MergeCampaign";
import TransferCampaign from "@/components/Campaign/modals/TransferCampaign";
import CampaignSearch from "../CampaignSearch";
import { useUsers } from "@/services/user";
import { useEffect, useState } from "react";
import { useArtists, useSongs } from "@/services/artist";
import { IconCurrencyDollar, IconMusic, IconX } from "@tabler/icons-react";
import NewMultiSelect from "@/components/CustomComponents/NewMultiSelect";
import Search from "@/components/CustomComponents/NewSearch";

interface SettingsProps {
  campaign: Campaign;
  mutate: any;
  transferable: any;
  style?: string;
}
const SettingsTab = ({ campaign, mutate, transferable, style }: SettingsProps) => {
  const toggleTracking = async () => {
    await updateTracking(campaign);
    mutate();
  };
  const [name, setName] = useState(campaign.name);
  const [instagramBudget, setInstagramBudget] = useState(
    campaign.instagramBudget.toString(),
  );
  const [tiktokBudget, setTiktokBudget] = useState(
    campaign.tiktokBudget.toString(),
  );
  const [streams, setStreams] = useState(campaign.streamTarget.toString());
  const [dailyStreams, setDailyStreams] = useState(
    campaign.dailyStreams.toString(),
  );
  const { data: artists } = useArtists();
  const [updating, setUpdating] = useState(false);
  const [artist, setArtist] = useState(
    campaign.artist
      ? { label: campaign.artist.name, value: campaign.artist.id }
      : null,
  );
  const { data: songs } = useSongs(campaign.artist.id);

  const [song, setSong] = useState(
    campaign.song
      ? { label: campaign.song.name, value: campaign.song.id }
      : null,
  );

  const artistOptions = artists
    ? artists.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    : [];

    const songOptions = songs?.Song
    ? songs.Song.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    : [];

  const { data: users } = useUsers();
  const [admins, setAdmins] = useState<string[]>([]);
  const removeAdmin = (email) => {
    console.log("remove", email)
    const newAdmins = admins.filter((adminEmail) => adminEmail !== email)
    console.log("newAdmins", newAdmins)
    setAdmins(newAdmins)
    }
    useEffect(() => {
setAdmins(campaign.admins.map((admin) => admin.user?.email) )
    }, [campaign])
console.log("admins", admins)
  const update = async () => {
    setUpdating(true);
    const adminIds = admins.map((admin) => (users.find((u) => admin === u.email)).id)
    console.log("users", users, {adminIds})
    await updateCampaign(campaign.id, {
      name,
      admins: adminIds,
      instagramBudget: parseInt(instagramBudget || "0"),
      tiktokBudget: parseInt(tiktokBudget || "0"),
      streamTarget: parseInt(streams || "0"),
      dailyStreams: parseInt(dailyStreams || "0"),
      artist: artist?.value || null,
      song: song?.value || null,
    });
    setUpdating(false);
    mutate();
  };
  console.log(campaign)

  return (
    <Flex direction="column" gap="5" py="6" pl={style === "mobile" ? "5" : "0"}>
      <Heading size="4">Campaign Settings</Heading>
      <Flex direction="row" gap="3" pt="4">
        <Flex width="15%">
          <Text>Tracking</Text>
        </Flex>
        <Flex gap="2">
          <Switch onCheckedChange={() => {toggleTracking()}} value={campaign.shouldTrack ? "On" : "Off"}/>
          <Text>{campaign.shouldTrack ? "On" : "Off"}</Text>
        </Flex> 
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text>Merge</Text>
        </Flex>
        <Flex gap="2">
          <CampaignSearch/>
        </Flex>
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text>Name</Text>
        </Flex>
        <Box>
          <TextField.Root
          value={name}
          onChange={(e) => {setName(e.target.value)}}
          placeholder="Name"
          radius="medium"
          style={{width: "300px", height: "36px"}}
          variant="soft"
          />
        </Box>
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text>Admins</Text>
        </Flex>
        <Flex direction="column" gap="2" width="300px">
          <NewMultiSelect 
            title="Search Users"
            options={users?.map((i) => (i.email)) || []}
            selected={admins}
            setSelected={setAdmins}
            style={{height: "36px"}}
          />
          <Flex gap="1">
            {admins.length ? admins?.map((i) => (
              <Badge radius="large" key={i}>
                {i}
                <IconButton variant="ghost" onClick={()=>removeAdmin(i)}>
                  <IconX className="icon-accent" height="10px"/>
                </IconButton>
              </Badge>
            )) : <Text size="1">No admins yet.</Text>}
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text>Artist</Text>
        </Flex>
        <Flex direction="column" gap="2" width="300px">
          <Search 
            placeholder="Search artists..."
            data={artistOptions|| []}
            onChange={setArtist}
            prompt="Enter artist name"
            def={artist || null}
          />
        </Flex>
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text>Song</Text>
        </Flex>
        <Flex direction="column" gap="2" width="300px">
          <Search 
            placeholder="Search songs..."
            data={songOptions|| []}
            onChange={setSong}
            prompt="Enter song name"
            def={song || null}
          />
        </Flex>
      </Flex>

      <Separator my="3" size="4"/>

      <Heading size="4">Budget</Heading>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text>Instagram</Text>
        </Flex>
        <Box>
          <TextField.Root
          value={instagramBudget}
          type="number"
          onChange={(e) => setInstagramBudget(e.target.value)}
          placeholder="Enter Instagram budget"
          radius="medium"
          style={{width: "300px", height: "36px"}}
          variant="soft"
          >
            <TextField.Slot pr="4">
              <IconCurrencyDollar className="icon-dark" width="20px"/>
            </TextField.Slot>
          </TextField.Root>
        </Box>
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text>TikTok</Text>
        </Flex>
        <Box>
          <TextField.Root
          value={tiktokBudget}
          type="number"
          onChange={(e) => {setTiktokBudget(e.target.value)}}
          placeholder="Enter Tiktok budget"
          radius="medium"
          style={{width: "300px", height: "36px"}}
          variant="soft"
          >
            <TextField.Slot pr="4">
              <IconCurrencyDollar className="icon-dark" width="20px"/>
            </TextField.Slot>
          </TextField.Root>
        </Box>
      </Flex>
      
      <Separator my="3" size="4"/>

      <Heading size="4">Spotify Goals</Heading>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text>Stream Goal</Text>
        </Flex>
        <Box>
          <TextField.Root
          value={streams}
          type="number"
          onChange={(e) => {setStreams(e.target.value)}}
          placeholder="Enter desired number..."
          radius="medium"
          style={{width: "300px", height: "36px"}}
          variant="soft"
          >
            <TextField.Slot pr="4">
              <IconMusic className="icon-dark" width="20px"/>
            </TextField.Slot>
          </TextField.Root>
        </Box>
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text>Daily Streams</Text>
        </Flex>
        <Box>
          <TextField.Root
          value={dailyStreams}
          type="number"
          onChange={(e) => {setDailyStreams(e.target.value)}}
          placeholder="Enter desired number..."
          radius="medium"
          style={{width: "300px", height: "36px"}}
          variant="soft"
          >
            <TextField.Slot pr="4">
              <IconMusic className="icon-dark" width="20px"/>
            </TextField.Slot>
          </TextField.Root>
        </Box>
      </Flex>
      <Flex direction="row" width="30%">
        <Button
          onClick={update}
          loading={updating}
          variant="solid"
        >
        Update
        </Button>
      </Flex>
    </Flex>
  );
};

export default SettingsTab;
