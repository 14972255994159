import TimeAgo from "javascript-time-ago";
import { Flex, Text } from "@radix-ui/themes";
import { formatNumber } from "@/lib/util";
import { memo, useRef, useState } from "react";
import { IconCalendarMonth, IconHeart, IconMessage, IconPlayerPlay, IconReload } from "@tabler/icons-react";
import Image from "next/image";
import * as Dialog from "@/components/CustomComponents/Dialog"


interface MobilePostProps {
    post: any
}

const timeAgo = new TimeAgo("en-US");
const formatter = new Intl.NumberFormat("en-US");

const MobilePost = ({
    post
}: MobilePostProps) => {
  const [holding, setHolding] = useState(false)
  const [clickStart, setClickStart] = useState(null);
  console.log("post", post)

  // Handler for the mouse down event
  const handleMouseDown = () => {
    console.log("touch start 1")
    setHolding(true);
    setClickStart(Date.now());
  };

  // Handler for the mouse up event
  const handleMouseUp = () => {
    if (holding) {
      // Clear the timer if the press was not long
      setHolding(false);
      
      if (clickStart !== null) {
        const clickDuration = Date.now() - clickStart;
        if (clickDuration < 200) {
          // Redirect
          window.open(post.url, "_blank")
          setHolding(false)
        }
        setClickStart(null); // Reset the click start time
      }
      
    }
  };

  // Handler for touch events
  const handleTouchStart = () => {
    console.log("touch start")
    setHolding(true);
    setClickStart(Date.now());
    
  };

  const handleTouchEnd = () => {
    if (holding) {
      setHolding(false);
      if (clickStart !== null) {
        const clickDuration = Date.now() - clickStart;
        if (clickDuration < 200) {
          // Redirect
          window.open(post.url, "_blank")
          setHolding(false);
        }
        setClickStart(null); // Reset the click start time
      }
    }
  };

  return ( 
    <>
    <Dialog.Root open={holding}>
      <Dialog.Overlay />
              <Dialog.Content style={{padding: 0, maxWidth: '100vw', position: 'absolute'}} 
              onInteractOutside={() => setHolding(false)}>
              <Flex className="img__wrap" justify="end" style={{position: "relative"}} >
                <div style={{height: "85vh", position :'fixed', width: '95vw', objectFit: "cover", top: '10vh'}}>
                <Image draggable={false} fill className="rounded-xl" alt=""src={`https://wvemedia.com/image-upload/${post?.thumbnail || post?.thumbnailSmall}`} style={{width: '100%', height: '100%', objectFit: "cover"}}/>
                <div style={{position: "absolute", bottom: 0, left: 0, zIndex: 1000}}>
                  <Flex className="img__description_layer_mobile">
                  <Flex className="img__description_mobile">
                    <Flex direction="column" gap="2" align="start" justify="end" p="4">
                  {post.views && (
                    <Flex direction="row" gap="2" justify="start" align="center"> 
                      <IconPlayerPlay className="icon" />
                      <Text size="5">{formatter.format(post.views)} Views</Text>
                    </Flex>
                  )}
                  {post.likes && (
                    <Flex direction="row" gap="2" justify="start" align="center"> 
                      <IconHeart className="icon" />
                      <Text size="5">{formatter.format(post.likes)} Likes</Text>
                    </Flex>
                  )}
                  {post.comments && (
                    <Flex direction="row" gap="2" justify="start" align="center"> 
                      <IconMessage className="icon"/>
                      <Text size="5">{formatter.format(post.comments)} Comments</Text>
                    </Flex>
                  )}
                  <Flex direction="row" gap="2" justify="start" align="center">
                    <IconCalendarMonth className="icon"/>
                    <Text size="5">
                      Posted{" "}
                      {new Date(post.postedAt.split("T")[0]).toLocaleDateString("en-US", {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                    </Text>
                  </Flex>
                  {post.lastScraped && (
                    <Flex direction="row" gap="2" justify="start" align="center"> 
                      <IconReload className="icon"/>
                      <Text size="5">Updated {timeAgo.format(new Date(post.lastScraped.split("T")[0]))}</Text>
                    </Flex>
                    )}
                  {post.username && (
                    <Text size="5" className="font-semibold">@{post.username}</Text>
                  )}
                </Flex>
                  </Flex>
                </Flex> 
                </div>
                </div>
               
                
              </Flex>
              </Dialog.Content>
            </Dialog.Root> 
    {(post.thumbnail || post.thumbnailSmall) && !post.missing && (
    <Flex key={post.id} direction="column" style={{cursor: 'pointer', width: '50%', height: '300px', overflow: "visible"}} 
    onMouseDown={handleMouseDown}
    onMouseUp={handleMouseUp}
    onTouchStart={handleTouchStart}
    onTouchEnd={handleTouchEnd}
   >
            <>
            
      <Flex className="img__wrap" justify="end">
        <div  >
        <Image fill className="rounded-xl" alt="" src={`https://wvemedia.com/image-upload/${post?.thumbnail || post?.thumbnailSmall}`} style={{width: '100%', height: '100%', objectFit: "cover"}}/>
        </div>
        <Flex
          className="rounded-xl" 
          direction="column" 
          justify="end">
          <Flex className="img__default_layer_mobile">
          <Flex className="img__default_mobile">
            <Flex direction="column" justify="end" align="start" gap="1" p="1" style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              width: '100%',
            }}
            >
              <Flex direction="row" justify="between" align="end" gap="2">
                {post.views && (
                  <Flex direction="row" gap="1" justify="start" align="center"> 
                    <IconPlayerPlay className="icon" width="12px" height="12px"/>
                    <Text size="1">{formatNumber(post.views)}</Text>
                  </Flex>
                )}
                {post.likes && (
                  <Flex direction="row" gap="1" justify="start" align="center"> 
                    <IconHeart className="icon" width="12px" height="12px" />
                    <Text size="1">{formatNumber(post.likes)}</Text>
                  </Flex>
                )}
                {post.comments && (
                  <Flex direction="row" gap="1" justify="start" align="center"> 
                    <IconMessage className="icon" width="12px" height="12px" />
                    <Text size="1">{formatNumber(post.comments)}</Text>
                  </Flex>
                )}
              </Flex>
            {post.username && (
                <Text size="1" className="font-semibold">@{post.username}</Text>
              )}
            </Flex>
          </Flex>
        </Flex>
          </Flex>
        </Flex>
        </>
    </Flex> )}
    </>
    );
};

export default memo(MobilePost)