import fetchJson from "@/lib/fetchJson";
import useSWR from "swr";

type DuplicateType = Post & { campaign: Campaign };
export const useDuplicates = () =>
  useSWR<DuplicateType[][]>("/post/duplicates", fetchJson);

export const deletePost = async (id: number) => {
  await fetchJson(`/post/${id}`, {
    method: "DELETE",
  });
};

export const usePostHistory = (id: string) =>
  useSWR(`/post/${id}/history`, fetchJson);
