import { Flex } from "@radix-ui/themes";
import { memo, useState } from "react";
import MobilePost from "./MobilePost";

interface Props {
    fullPosts: any;
    postsRange: [number, number];
}

const MobilePostsRow = ({
    fullPosts,
    postsRange,
}: Props) => {
  const posts = fullPosts.sort((a: any, b: any) => b.views - a.views);
  return (
    <Flex direction="row" gap="4">
        {(posts.slice(postsRange[0], postsRange[1])).map(
    (post: any) =>
    <MobilePost post={post} key={post.id}/>
  )}     
    </Flex>
  );
};

export default memo(MobilePostsRow);
