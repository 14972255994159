"use client";
import { useEffect, useRef } from "react";
import { Button, Flex, IconButton, TextField } from "@radix-ui/themes";
import { IconShare } from "@tabler/icons-react";
import * as Dialog from "@/components/CustomComponents/Dialog"

export default function NewShareLink({ id }: any) {
  const inputRef = useRef(null as any);

  useEffect(() => {
    setTimeout(
      () => (inputRef.current !== null ? inputRef.current?.select() : ""),
      1000,
    );
  }, []);

  const copy = () => { };

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <IconButton variant="ghost">
          <IconShare className="icon"/>
        </IconButton>
      </Dialog.Trigger>
    <Dialog.Portal>
      <Dialog.Content>
        <Dialog.Title>Share</Dialog.Title>
        <TextField.Root className="" value={id} placeholder="Name" ref={inputRef} />

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
